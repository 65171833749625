var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-full p-t90 bg-gray"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5 col-sm-12 text-black"},[_c('span',{staticClass:"font-30 font-weight-600 text-black text-uppercase"},[_vm._v(_vm._s(_vm.$t(_vm.alternativeHeadline)))]),_vm._v(" "),_c('div',{staticClass:"section-head text-uppercase"},[_c('h2',{staticClass:"font-40"},[_vm._v(_vm._s(_vm.$t(_vm.pushForward)))])]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm._f("truncate")(_vm.$t(_vm.articleResume),300)))]),_vm._v(" "),(_vm.category.slug)?_c('nuxt-link',{staticClass:"btn-half site-button button-lg m-b15",attrs:{"to":_vm.getLocalizedRoute({
                        name: 'news-category-slug'
                        , params: {
                            category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, _vm.category.slug, 'tag')
                            , slug: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, _vm.slug, 'article')
                        }
                    })}},[_c('span',[_vm._v(_vm._s(_vm.$t('plus d\'infos')))]),_vm._v(" "),_c('em')]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-md-7 col-sm-12"},[_c('WelcomeDefaultCarousel')],1)]),_vm._v(" "),_c('div',{staticClass:"hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right"},[(_vm.foundingDate)?_c('strong',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.foundingDate)+" ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"text-black"},[_vm._v(_vm._s(_vm.$t('D\'Experience Professionnelle')))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }